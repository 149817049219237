@import "~video-react/styles/scss/video-react"; // or import scss

.App {
  height: 100vh;

  &__version {
    font-weight: bold;
  }


}

.ant-select-item-option-content {
  white-space: break-spaces;
  word-break: break-word;
}

.remarksRow {
  .ant-select {
    height: auto !important;
    white-space: break-spaces;
    word-break: break-word;
    width: 14rem;

    .ant-select-selector {
      height: auto !important;
      white-space: break-spaces;
      word-break: break-word;
      width: 14rem;

      span {
        white-space: break-spaces;
        word-break: break-word;
        width: 14rem;
      }
    }
  }
}

.qaRow {
  line-height: normal;

  .ant-select {
    height: auto !important;
    white-space: break-spaces;
    word-break: break-word;

    .ant-select-selector {
      height: auto !important;
      white-space: break-spaces;
      word-break: break-word;

      span {
        display: flex;
        align-items: center;
        justify-content: start;
        white-space: break-spaces;
        word-break: break-word;
        line-height: normal;
        padding-top: .25rem;
        padding-bottom: .25rem;
        caret-color: transparent;
      }
    }
  }
}

.table-row-dark {
  background-color: rgb(231, 60, 60);
}